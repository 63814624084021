import { httpService } from './http.service.js'

export const creatorService = {
    updateCreatorDetails,
    getStreamTokenClient,
    getCreator,
    sendForApproval,
    searchUsers,
    getFeed,
    addFeedItem,
    validateOTP,
    addComment,
    getComments,
    deleteComment,
    votePoll,
    getReplies,
    updateSubscriptionPrice,
    updateSubscriptionDesc,
    sendOTP,
    addReply,
    deleteReply,
    createShow,
    handleLikeComment,
    signVideoUpload,
    getFollowers,
    getStreamCredentials,
    startShow,
    endShow,
    banUser,
    unbanUser,
    sendEmail
}

async function sendOTP(phone) {
    try {
        const confirm = await httpService.post('handle-creator/send-otp', { phone })
        return confirm
    }
    catch (err) {
        return err
    }
}

async function validateOTP(phone, otp) {
    try {
        const creator = await httpService.post('handle-creator/validate-otp ', { phone, otp })
        return creator
    }
    catch (err) {
        return err
    }
}

async function deleteComment(comment) {
    const res = await httpService.delete('handle-creator/delete-comment', { comment })
    return res
}

async function addFeedItem(post) {
    const i = await httpService.post('handle-creator/add-post', { post })
    return i
}

async function getStreamCredentials() {
    const cred = await httpService.get('handle-creator/streamer-credentials')
    return cred
}

async function getFeed(from = 0) {
    const feed = await httpService.get(`handle-creator/get-feed/${from}`)
    return feed
}

async function getComments(postId, from = 0) {
    const comments = await httpService.get(`handle-creator/get-comments/${postId}/${from}`)
    return comments
}

async function addReply(commentId, reply) {
    const newReply = await httpService.post('handle-creator/add-reply', { commentId, reply })
    return newReply
}

async function deleteReply(reply, commentId) {
    const res = await httpService.delete('handle-creator/delete-reply', { commentId, reply })
    return res
}

async function addComment(comment) {
    const newComment = await httpService.post('handle-creator/add-comment', { comment })
    return newComment
}

async function handleLikeComment(commentId, like) {
    const confirm = await httpService.post('handle-creator/handle-like-comment', { commentId, like })
    return confirm
}

async function getReplies(commentId, from = 0) {
    const replies = await httpService.get(`handle-creator/get-replies/${commentId}/${from}`)
    return replies
}

async function votePoll(postId, option) {
    const opt = await httpService.post('handle-creator/vote-poll', { postId, option })
    return opt
}

async function signVideoUpload(fileName, fileType) {
    const signedUrl = await httpService.post('handle-creator/sign-video-upload', { fileName, fileType })
    return signedUrl
}

async function searchUsers(search) {
    const users = await httpService.get(`handle-creator/search-users/${search}`)
    return users
}

async function updateSubscriptionPrice(price) {
    const newPrice = await httpService.post('handle-creator/update-subscription-price', { price })
    return newPrice
}

async function updateSubscriptionDesc(description) {
    const newDesc = await httpService.post('handle-creator/update-subscription-desc', { description })
    return newDesc
}

async function getCreator() {
    const newCreator = await httpService.get('handle-creator/get-creator')
    return newCreator
}

async function sendForApproval(details) {
    const creator = await httpService.post('handle-creator/request-approval', details)
    return creator
}

async function updateCreatorDetails(change) {
    const newCreator = await httpService.post('handle-creator/update-info', change)
    return newCreator
}

async function getFollowers(from = 0) {
    const followers = await httpService.get(`handle-creator/get-followers/${from}`)
    return followers
}

async function getStreamTokenClient({ uid, role, channel }) {
    const token = await httpService.get(`rtc/${channel}/${role}/uid/${uid}`)
    return token
}

async function createShow(show) {
    const res = await httpService.post('handle-creator/create-show', show)
    return res
}

async function endShow(showId) {
    const res = await httpService.post('handle-creator/end-show', { showId })
    return res
}

async function startShow(showId) {
    const res = await httpService.post('handle-creator/start-show', { showId })
    return res
}

async function banUser(userId) {
    const res = await httpService.post('handle-creator/ban-user', { userId })
    return res
}

async function unbanUser(userId) {
    const res = await httpService.post('handle-creator/unban-user', { userId })
    return res
}

async function sendEmail(details) {
    const res = await httpService.post('handle-user/send-email', details)
    return res
}
