import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { setPopup, setUpperPopup } from '../store/actions/general.actions'
import { addShow } from '../store/reducers/creator.reducer'
import { httpService } from '../services/http.service'
import { Loader } from '../cmps/loader'
import { creatorService } from '../services/creator.service'
const prices = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, 150, 200, 250, 500]

export function Create() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const creator = useSelector((state) => state.creator)
    const descRef = useRef()
    const longDescRef = useRef()
    const linkRef = useRef()
    const titleRef = useRef()
    const priceRef = useRef()
    const [date, setDate] = useState(new Date())
    const [tasks, setTasks] = useState([])
    const img = useRef()
    const imgPc = useRef()
    const [uploadsState, setUploadsState] = useState({
        img: '',
        imgpc: '',
        video: ''
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!creator.phone) navigate('/login')
        else if (creator.status === 'banned') navigate('/ban')
        else if (creator.status !== 'approved') navigate('/register')
    }, [])

    const createShow = async (e) => {
        e.preventDefault()
        try {
            if (new Date() > new Date(date)) {
                dispatch(setUpperPopup('date'))
                return
            }
            dispatch(setPopup('upload-event'))
            if (!img.current.files[0]) {
                dispatch(setUpperPopup('imgRequired'))
                dispatch(setPopup(''))
                return
            }
            if (priceRef.current.value < 1 || priceRef.current.value > 1000) {
                dispatch(setUpperPopup('price'))
                dispatch(setPopup(''))
                return
            }
            if (linkRef.current.value && !linkRef.current.value.startsWith('http')) {
                dispatch(setUpperPopup('broken-link'))
                dispatch(setPopup(''))
                return
            }

            const utcString = date.toUTCString()
            const image = await httpService.uploadImage(img.current.files[0])
            const imagePc = await httpService.uploadImage(imgPc.current.files[0])

            const show = {
                date: utcString,
                title: titleRef.current.value,
                shortDescription: descRef.current.value,
                longDescription: longDescRef.current.value,
                link: linkRef.current.value,
                img: image,
                imgPc: imagePc,
                price: priceRef.current.value,
                tasks
            }

            const newShow = await creatorService.createShow(show)
            dispatch(addShow(newShow))
            dispatch(setPopup('created-show'))
            navigate('/')
        }

        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorCreate'))
            dispatch(setPopup(''))
        }
    }

    const handleUpload = (e) => {
        const { files, name } = e.target
        setUploadsState({ ...uploadsState, [name]: files[0] ? files[0].name : '' })
    }

    const handleDate = (e) => {
        const { value } = e.target
        const date = new Date(value)
        if (new Date(Date.now()) > new Date(date)) {
            dispatch(setUpperPopup('date'))
            return
        }
        setDate(date)
    }

    const addTask = () => {
        let t = { description: '', id: Date.now(), price: '' }
        setTasks([...tasks, t])
    }

    const removeTask = (id) => {
        setTasks(tasks.filter(task => task.id !== id))
    }

    const editTask = (ev, id, key) => {
        let t = tasks.map(task => task.id === id ? { ...task, [key]: ev.target.value } : task)
        setTasks(t)
    }

    if (!creator.phone || creator.status !== 'approved') return <div className="home"><Loader /></div>

    return <form className='create' onSubmit={createShow}>
        <div>
            <div className='all-select-wrapper'>
                <div className='h3-wrapper'>
                    <h3>Ticket price</h3>
                    <div className='select-wrapper' style={{ minHeight: '43px' }} required>
                        <select ref={priceRef} required>
                            <option value="">Select price</option>
                            {prices.map((price, idx) => <option key={idx} value={price}>{price}₪</option>)}
                        </select>
                    </div>
                </div>
                <div className='h3-wrapper'>
                    <h3>Date</h3>
                    <div className='select-wrapper' style={{ minHeight: '43px' }}>
                        <input type="datetime-local" className='date-special' onChange={handleDate} required></input>
                    </div>
                </div>
                <div className='h3-wrapper' style={{ width: '100%' }}>
                    <h3>Image (Mobile)</h3>
                    <div className='select-wrapper'>
                        <input id='img' name='img' className="non-appear" type="file" placeholder="Upload your image" accept="image/*" ref={img} onChange={handleUpload} />
                        <label htmlFor='img' className='clickable performer upload-video' style={{ textAlign: 'center', display: 'block', fontSize: '24px', width: '100%' }} >
                            {uploadsState.img ? <span>{uploadsState.img.slice(0, 12) + '...'}</span> : <span className="material-symbols-outlined">drive_folder_upload</span>}
                        </label>
                    </div>
                </div>
                <div className='h3-wrapper' style={{ width: '100%' }}>
                    <h3>Image (PC)</h3>
                    <div className='select-wrapper'>
                        <input id='imgpc' name='imgpc' className="non-appear" type="file" placeholder="Upload your image" accept="image/*" ref={imgPc} onChange={handleUpload} />
                        <label htmlFor='imgpc' className='clickable performer upload-video' style={{ textAlign: 'center', display: 'block', fontSize: '24px', width: '100%' }} >
                            {uploadsState.imgpc ? <span>{uploadsState.imgpc.slice(0, 12) + '...'}</span> : <span className="material-symbols-outlined">drive_folder_upload</span>}
                        </label>
                    </div>
                </div>
                <div className='h3-wrapper' style={{ width: '100%' }}>
                    <h3>Title</h3>
                    <input maxLength={50} required ref={titleRef} className='link' placeholder='Best event ever' />
                </div>
                <div className='h3-wrapper' style={{ width: '100%' }}>
                    <h3>short description</h3>
                    <textarea maxLength={220} required className='fund-desc' placeholder='Tell us more about the event...' ref={descRef} />
                </div>
                <div className='h3-wrapper' style={{ width: '100%' }}>
                    <h3>Long description (optional)</h3>
                    <textarea maxLength={1200} className='fund-desc' placeholder='Add more details if any...' ref={longDescRef} />
                </div>
                <div className='h3-wrapper' style={{ width: '100%' }}>
                    <h3>Link (optional)</h3>
                    <input className='link' placeholder='https://link-to-page' ref={linkRef} maxLength={100} />
                </div>
            </div>

            {/* <div className='h3-wrapper' style={{ width: '100%' }}>
                <h3>Missions (optional)</h3>
            </div>

            <div className="soltiz">
                <div className="tasks-wrapper">
                    {tasks.length ? tasks.map((task) => <div key={task.id} className="task">
                        <textarea value={task.description} onChange={(ev) => editTask(ev, task.id, 'description')} placeholder="Task" />
                        <div className="lower">
                            <input placeholder="Price" type="number" value={task.price} onChange={(ev) => editTask(ev, task.id, 'price')} />
                            <div className="remove" onClick={() => removeTask(task.id)}>X</div>
                        </div>

                    </div>) :
                        <p>Your next event will not include any paid missions.</p>}
                </div>

                <div className="add" onClick={addTask}>+</div>
            </div> */}
        </div>
        <button>Create!</button>
    </form>
}