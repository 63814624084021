import { creatorService } from "../../services/creator.service.js"
import { Create } from "./create.jsx"
import { useEffect, useRef, useState } from 'react'
import { setUpperPopup, setPopup } from "../../store/actions/general.actions.js"
import { setSubscription } from "../../store/reducers/creator.reducer.js"
import { Post } from "./post.jsx"
import { useSelector } from "react-redux"

export function Feed({ dispatch }) {
    const creator = useSelector(state => state.creator)
    const [feed, setFeed] = useState([])
    const [from, setFrom] = useState(0)
    const [loader, setLoader] = useState(true)
    const [scrollToTop, setScrollToTop] = useState(false)
    const [edit, setEdit] = useState(false)
    const targetRef = useRef(null)
    const debounce = useRef(false)
    const debounce2 = useRef(false)

    useEffect(() => {
        window.addEventListener('scroll', loadMore)
        return () => window.removeEventListener('scroll', loadMore)
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScrollTopButton)
    }, [])

    useEffect(() => {
        loadFeed(from)
    }, [from])

    const handleScrollTopButton = () => {
        if (debounce2.current) return
        if (window.scrollY > 1000) setScrollToTop(true)
        else setScrollToTop(false)
        debounce2.current = true
        setTimeout(() => { debounce2.current = false }, 1000)
    }

    const loadFeed = async () => {
        try {
            const f = await creatorService.getFeed(from)
            setFeed(feed.concat(f))
            if (f.length < 10) setLoader(false)
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const loadMore = () => {
        if (debounce.current) return
        if (!targetRef.current) {
            window.removeEventListener('scroll', loadMore)
            return
        }
        const rect = targetRef.current.getBoundingClientRect()
        if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            debounce.current = true
            setTimeout(() => { debounce.current = false }, 800)
            setFrom((from) => from + 10)
        }
    }

    const addToFeed = (item) => {
        setFeed([item, ...feed])
    }

    const onDescChange = async (ev) => {
        ev.preventDefault()
        try {
            const newDesc = ev.target[0].value
            if (newDesc === creator.subscription?.description) return
            if (newDesc.length > 200 || newDesc.length < 2) return dispatch(setUpperPopup('errorDesc'))
            await creatorService.updateSubscriptionDesc(newDesc)
            dispatch(setSubscription({ description: newDesc }))
            setEdit('')
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const onPriceChange = async (ev) => {
        ev.preventDefault()
        try {
            const newPrice = Number(ev.target[0].value)
            if (newPrice === creator.subscription?.price) return
            if (newPrice < 0 || newPrice > 1000) return dispatch(setUpperPopup('errorPrice'))
            await creatorService.updateSubscriptionPrice(newPrice)
            dispatch(setSubscription({ price: newPrice, lastPriceChange: new Date().toString() }))
            dispatch(setUpperPopup('price-change-succeed'))
            setEdit('')
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const priceChange = creator.subscription?.lastPriceChange ? Date.now() - new Date(creator.subscription.lastPriceChange).getTime() > 2_592_000_000 : true

    return <div className="feed">
        <Create addToFeed={addToFeed} />
        {feed.map((item, idx) => <Post post={item} key={idx} dispatch={dispatch} />)}
        {scrollToTop && <div className="scroll-top" onClick={() => { window.scrollTo(0, 0); setTimeout(() => setScrollToTop(false), 1000) }}><span className="material-symbols-outlined">arrow_upward</span></div>}
        {loader && <div style={{ margin: 'auto', position: 'relative' }}><div ref={targetRef} className="loader loader-block"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
    </div>
}