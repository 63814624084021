import { ShowCard } from '../show-card.jsx'
import { setStreamInfo } from '../../store/actions/general.actions'

export function Shows({ navigate, shows, dispatch }) {

    const stream = (show) => {
        dispatch(setStreamInfo(show))
        navigate('/stream-control')
    }

    if (shows.length) return <div>
        <div className="events-container">
            {shows.map(show => <ShowCard key={show._id} show={show} stream={stream} />)}
        </div>
    </div>

    return <div className="no-events">
        <h1>You don't have any shows yet, you can create one right <span onClick={() => navigate('/create')} className="clickable main-color">here</span>.</h1>
        <img src={require('../../style/imgs/no-events.png')} />
    </div>
}