import { useState, useMemo } from "react"
import { getkHowLongAgo, putKandM } from "../../services/utils"
import { creatorService } from "../../services/creator.service"
import { setUpperPopup } from "../../store/actions/general.actions"
import { useSelector, useDispatch } from "react-redux"
import { Comment } from "./comment"

export function Post({ post }) {
    const dispatch = useDispatch()
    const creator = useSelector(state => state.creator)
    const { date, text, image, video, poll, commentsAmount, likesAmount, _id } = post
    const [commentsOpen, setCommentsOpen] = useState(false)
    const [comments, setComments] = useState([])
    const [from, setFrom] = useState(0)
    const [showMore, setShowMore] = useState(true)

    function getVotesPercentages() {
        const def = ['0%', '0%', '0%', '0%']
        if (!poll) return def
        let totalVotes = 0
        poll.options.forEach(op => totalVotes += op.votes)
        if (!totalVotes) return def
        const rawPercentages = []
        let totalPercentage = 0
        poll.options.forEach((op) => {
            const percentage = Math.floor(op.votes / totalVotes * 100)
            rawPercentages.push(percentage)
            totalPercentage += percentage
        })
        let remainder = 100 - totalPercentage
        for (let i = 0; remainder > 0; i = (i + 1) % poll.options.length) {
            rawPercentages[i]++
            remainder--
        }
        const percentages = rawPercentages.map(p => p + '%')
        return percentages
    }

    const loadComments = async () => {
        try {
            if (commentsOpen) return
            setCommentsOpen(true)
            const c = await creatorService.getComments(post._id, from)
            if (c.length < 10) setShowMore(false)
            else setFrom(from + 10)
            setComments(comments.concat(c))
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const addComment = async (ev) => {
        ev.preventDefault()
        try {
            const text = ev.target[1].value
            if (!text) return
            const comment = {
                text,
                nickName: creator.nickName,
                image: creator.image,
                postId: _id,
            }
            const c = await creatorService.addComment(comment)
            setComments([c, ...comments])
            ev.target[0].value = ''
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const percentages = useMemo(() => getVotesPercentages(), [])

    return <div className="post">
        <p className="time">{getkHowLongAgo(date)}</p>
        <p className="text">{text}</p>
        {image && <img src={image} className="img-main" />}
        {video && <video src={video} controls />}
        {poll && <div className="poll">
            {poll.options.map((op, idx) => <div key={idx} className="opt-wrapper">
                {op.image && <img src={op.image} />}
                <div className="text-wrapper">
                    <p className="text">{op.text}</p>
                    <div className="bar" style={{ width: percentages[idx] }} />
                    <p className="percent">{percentages[idx]}</p>
                </div>

            </div>)}
        </div>}
        <div className="lower">
            <div>
                <span className="material-symbols-outlined" style={{ fill: 'red' }}>thumb_up</span>
                <p>{putKandM(likesAmount)}</p>
            </div>
            <div onClick={loadComments} className='actionable'>
                <span className="material-symbols-outlined">comment</span>
                <p>{putKandM(commentsAmount)}</p>
            </div>
        </div>
        {commentsOpen && <form className="add-comment" onSubmit={addComment}>
            <button><span className="material-symbols-outlined mirror">send</span></button>
            <input type="text" placeholder="הגב על הפוסט" />
            <img src={creator.image} className="profile-mini" />
        </form>}
        {(commentsOpen && comments.length) ? <div className="comments">
            {comments.map((c, idx) => <Comment creator={creator} comment={c} key={idx} postId={_id} />)}
        </div> : null}
        {(commentsOpen && showMore) ? <div className='show-replies'>
            <span className="material-symbols-outlined">keyboard_arrow_down</span>
            <p onClick={loadComments}>Show more</p>
        </div> : null}
    </div>
}