import { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setImage, setNickName, setDescription, setBanner } from '../../store/reducers/creator.reducer'
import { setUpperPopup } from '../../store/actions/general.actions'
import { creatorService } from '../../services/creator.service'
import { httpService } from "../../services/http.service"
import { Loader } from '../loader'
import { isMobile } from "react-device-detect"

export function Profile({ creator }) {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({ image: false, banner: false })
    const [editIcon, setEditIcon] = useState({ image: false, banner: false })
    const [editName, setEditName] = useState(false)
    const [editDesc, setEditDesc] = useState(false)
    const name = useRef()
    const desc = useRef()

    useEffect(() => {
        if (name.current) {
            name.current.value = creator.nickName
            name.current.focus()
        }
    }, [editName])

    useEffect(() => {
        if (desc.current) {
            desc.current.value = creator.description
            desc.current.focus()
        }
    }, [editDesc])

    const onNameChange = async (ev) => {
        ev.preventDefault()
        try {
            const newName = name.current.value
            if (!newName || newName === creator.channelName) return
            if (newName.length > 20 || newName.length < 2) return dispatch(setUpperPopup('errorName'))
            await creatorService.updateCreatorDetails({ nickName: newName })
            dispatch(setNickName(newName))
            dispatch(setUpperPopup('name-changed'))
            setEditName(false)
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const onDescChange = async (ev) => {
        ev.preventDefault()
        try {
            const newDesc = desc.current.value
            if (newDesc === creator.description) return
            if (newDesc.length > 200 || newDesc.length < 2) return dispatch(setUpperPopup('errorDesc'))
            await creatorService.updateCreatorDetails({ description: newDesc })
            dispatch(setDescription(newDesc))
            dispatch(setUpperPopup('desc-changed'))
            setEditDesc(false)
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const onImageChange = async (ev, type) => {
        try {
            if (!ev.target.files[0]) return
            if (!ev.target.files[0].type.startsWith('image/')) return dispatch(setUpperPopup('image-only'))
            setLoader({ ...Loader, [type]: true })
            const uploaded = await httpService.uploadImage(ev.target.files[0])
            await creatorService.updateCreatorDetails({ [type]: uploaded })
            if (type === 'image') dispatch(setImage(uploaded))
            else dispatch(setBanner(uploaded))
            setLoader({ ...Loader, [type]: false })
        }
        catch (err) {
            dispatch(setUpperPopup('errorServer'))
            setLoader(false)
        }
    }

    return <div className='profile'>
        <div className='banner-wrapper'>
            {loader.banner ? <Loader /> : <>
                <input type="file" className="non-appear" accept="image/*" id='banner-field' onChange={(e) => onImageChange(e, 'banner')} />
                <label htmlFor='banner-field'>
                    <img onMouseEnter={() => setEditIcon({ ...editIcon, banner: true })} onMouseLeave={() => setEditIcon({ image: false, banner: false })} src={creator.banner} alt='creator image' />
                    {(editIcon.banner && !isMobile) && <span className="edit-icon material-symbols-outlined" onMouseEnter={() => setEditIcon({ ...editIcon, banner: true })} onMouseLeave={() => setEditIcon({ image: false, banner: false })}>add_a_photo</span>}
                </label>
            </>}
        </div>
        <div className='profile-lower'>
            <div className='img-wrapper'>
                {loader.image ? <Loader /> : <>
                    <input type="file" className="non-appear" accept="image/*" id='image-field' onChange={(e) => onImageChange(e, 'image')} />
                    <label htmlFor='image-field'>
                        <img onMouseEnter={() => setEditIcon({ ...editIcon, image: true })} onMouseLeave={() => setEditIcon({ image: false, banner: false })} src={creator.image} alt='creator image' />
                        {(editIcon.image && !isMobile) && <span className="edit-icon material-symbols-outlined" onMouseEnter={() => setEditIcon({ ...editIcon, image: true })} onMouseLeave={() => setEditIcon({ image: false, banner: false })}>add_a_photo</span>}
                    </label>
                </>}
            </div>
            <div className='profile-details'>
                <div className='name-wrapper'>
                    <div className='edit-button'><span className="material-symbols-outlined" onClick={() => setEditName(!editName)}>{editName ? 'chevron_left' : 'edit'}</span></div>
                    {editName ? <form onSubmit={onNameChange} className='edit-name'>
                        <input className='name border' maxLength={20} ref={name} />
                        <button className='edit-button'><span className="material-symbols-outlined">check</span></button>
                    </form> :
                        <p className='name'>{creator.nickName}</p>}
                </div>
                {/* <div className='name-wrapper'>
                    <div className='edit-button'><span className="material-symbols-outlined" onClick={() => setEditDesc(!editDesc)}>{editDesc ? 'chevron_left' : 'edit'}</span></div>
                    {editDesc ? <form onSubmit={onDescChange} className='edit-name'>
                        <textarea className='name border' maxLength={200} ref={desc} />
                        <button className='edit-button'><span className="material-symbols-outlined">check</span></button>
                    </form> :
                        <p className='name desc'>{creator.description}</p>}
                </div>
                {!editDesc && <p className='color-main'>{creator.followersAmount} followers</p>} */}
            </div>
        </div>
    </div>
}