import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getkHowLongAgo, putKandM } from '../../services/utils.js'
import { creatorService } from '../../services/creator.service.js'
import { setUpperPopup } from '../../store/actions/general.actions.js'

export function Comment({ comment, creator }) {
    const dispatch = useDispatch()
    const [likesAmount, setLikesAmount] = useState(comment.likesAmount)
    const [userLiked, setUserLiked] = useState(comment.likedByUser)

    const [from, setFrom] = useState(0)
    const [replies, setReplies] = useState([])
    const [isReplying, setIsReplying] = useState(false)
    const [showReplies, setShowReplies] = useState(comment.repliesAmount > 0)
    const [deleted, setDeleted] = useState(false)

    const addReply = async (ev) => {
        ev.preventDefault()
        try {
            const text = ev.target[1].value
            if (!text) return
            const reply = {
                text,
                nickName: creator.nickName,
                image: creator.image,
            }
            const r = await creatorService.addReply(comment._id, reply)
            setReplies([r, ...replies])
            setShowReplies(true)
            ev.target[0].value = ''
            setIsReplying(false)
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const deleteReply = async (reply) => {
        try {
            await creatorService.deleteReply(reply, comment._id)
            setReplies(replies.filter(r => r.text !== reply.text))
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const handleLike = async () => {
        try {
            const newState = await creatorService.handleLikeComment(comment._id, !userLiked)
            setLikesAmount(newState ? likesAmount + 1 : likesAmount - 1)
            setUserLiked(newState)
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const loadReplies = async () => {
        try {
            const r = await creatorService.getReplies(comment._id, from)
            if (r.length < 10) setShowReplies(false)
            else setFrom(from + 10)
            setReplies(replies.concat(r))
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const deleteComment = async () => {
        try {
            await creatorService.deleteComment(comment)
            dispatch(setUpperPopup('comment-deleted'))
            setDeleted(true)
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    if (deleted) return <></>

    return <div className="comment">
        <img src={comment.image} className="profile-mini" />
        <div style={{ flex: '1' }}>
            <p className='name'>{comment.nickName} <span>{getkHowLongAgo(comment.date)}</span></p>
            <p>{comment.text}</p>
            <div className='lower-comment'>
                <span onClick={handleLike} style={{ fontVariationSettings: userLiked ? "'FILL' 1" : '' }} className="material-symbols-outlined test">thumb_up_alt</span>
                <p>{putKandM(likesAmount)}</p>
                <p onClick={deleteComment} className='reply'>Delete</p>
                <p onClick={() => setIsReplying(!isReplying)} className='reply'>{isReplying ? 'Close' : 'Reply'}</p>
            </div>
            {isReplying && <form className="add-comment" onSubmit={addReply}>
                <button><span className="material-symbols-outlined mirror">send</span></button>
                <input type="text" placeholder="הגב" />
                <img src={creator.image} className="profile-mini" />
            </form>}
            {replies.length ? <div className="replies">
                {replies.map((r, idx) => <Reply reply={r} key={idx} deleteReply={deleteReply} />)}
            </div> : null}
            {(showReplies && replies.length < comment.repliesAmount) ? <div className='show-replies'>
                <span className="material-symbols-outlined">keyboard_arrow_down</span>
                <p onClick={loadReplies}>Show replies</p>
            </div> : null}
        </div>
    </div>
}

function Reply({ reply, deleteReply }) {
    return <div className="comment">
        <img src={reply.image} className="profile-mini" />
        <div>
            <p className='name'>{reply.nickName} <span>{getkHowLongAgo(reply.date)}</span></p>
            <p>{reply.text}</p>
            <p onClick={() => deleteReply(reply)} className='delete-reply'>Delete</p>
        </div>
    </div>
}
