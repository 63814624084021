import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { httpService } from '../services/http.service.js'
import { setUpperPopup } from '../store/actions/general.actions.js'
import { creatorService } from '../services/creator.service'
import { login } from '../store/reducers/creator.reducer'
import { Loader } from '../cmps/loader'

export function Register() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const creator = useSelector((state) => state.creator)
    const [loader, setLoader] = useState(false)
    const [file, setFile] = useState(null)
    const nameRef = useRef()
    const youtubeRef = useRef()
    const tiktokRef = useRef()
    const instagramRef = useRef()
    const imgRef = useRef()

    useEffect(() => {
        if (creator.status === 'approved') navigate('/')
    }, [creator.status])

    const sendForApproval = async () => {
        if (!nameRef.current.value || !imgRef.current.files[0]) return dispatch(setUpperPopup('emptyField'))
        setLoader(true)
        try {
            const nickName = nameRef.current.value
            const uploaded = await httpService.uploadImage(imgRef.current.files[0])
            const socialLinks = {
                youtube: youtubeRef.current.value,
                tiktok: tiktokRef.current.value,
                instagram: instagramRef.current.value
            }
            const user = await creatorService.sendForApproval({ nickName, image: uploaded, socialLinks })
            dispatch(login(user))
            if (user) navigate('/')
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
        setLoader(false)
    }

    const handleFile = (e) => {
        setFile(e.target.files[0])
    }

    if (creator.status === 'pending') return <div className="home center" style={{ padding: '10px' }}>
        <div className='register-complete'>
            <h1>Thank you for registering!</h1>
            <p>Your request is now being reviewed by our team. The process may take up to 2 days.Have a great day and see you soon!</p>
        </div>
    </div>

    if (creator.status === 'rejected') return <div className="home center" style={{ padding: '10px' }}>
        <div className='register-complete'>
            <h1>Your account has been rejected to use this platform.</h1>
            <p>If you think we made a mistake, contact our support team <span onClick={() => window.gist.chat('open')}>here</span>.</p>
        </div>
    </div>

    return <section className="home register">
        <h1>Register</h1>
        <p>*In order to use this platform, you must first provide some information about yourself. please fill the short form below and our team will soon contact you.</p>
        <div className='form' onSubmit={sendForApproval}>
            <h3>Nickname</h3>
            <input type="text" placeholder="Enter your nickname" required maxLength={15} ref={nameRef} name='nickName' />
            <h3>Image</h3>
            <p style={{ marginBottom: 0 }}>*This image is used for verification and should be a close selfi with one hand doing a thumbs up.</p>
            <label htmlFor='img'><div className="upload-img"><img src={require('../style/imgs/img-upload.png')} />{file && file.name}</div></label>
            <input id='img' className="non-appear" type="file" placeholder="Upload your image" accept="image/*" required ref={imgRef} onChange={handleFile} name='image' />
            <h3>Social (optional)</h3>
            <div className='social-wrapper'>
                <img src={require('../style/imgs/contact/youtube.png')} />
                <input type="text" placeholder="Youtube" ref={youtubeRef} maxLength={80} name='youtube' />
            </div>
            <div className='social-wrapper'>
                <img src={require('../style/imgs/contact/tiktok.png')} />
                <input type="text" placeholder="TikTok" ref={tiktokRef} maxLength={80} name='tiktok' />
            </div>
            <div className='social-wrapper'>
                <img src={require('../style/imgs/contact/instagram.png')} />
                <input type="text" placeholder="Instagram" ref={instagramRef} maxLength={80} name='instagram' />
            </div>
            {loader ? <div className='center' style={{ width: '100%', height: '138px' }}><Loader moreClasses='loader-block' /></div> : <button onClick={sendForApproval}>Send</button>}
        </div>

    </section >
}