import { useEffect, useMemo, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { Profile } from '../cmps/creator/profile'
import { Error } from './error'
import { Loader } from "../cmps/loader"
import { Shows } from "../cmps/creator/shows"
import { Feed } from "../cmps/creator/feed"
import { Users } from "../cmps/creator/users"
const options = ['feed', 'shows', 'users']

export function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const creator = useSelector((state) => state.creator)
    const [opt, setOpt] = useState('feed')

    useEffect(() => {
        if (!creator.phone) navigate('/login')
        else if (creator.status === 'banned') navigate('/ban')
        else if (creator.status !== 'approved') navigate('/register')
        window.scrollTo(0, 0)
    }, [])

    const shows = useMemo(() => {
        if (!creator.shows) return []
        return Object.values(creator.shows).sort((a, b) => new Date(b.date) - new Date(a.date))
    }, [creator.shows])

    if (!creator.phone || creator.status !== 'approved') return <div className="home"><Loader /></div>

    try {
        return <section className="home">
            <Profile creator={creator} />
            <div className="options-bar">
                {options.map(op => <p className={op === opt ? 'chosen' : ''} key={op} onClick={() => setOpt(op)}>{op.charAt(0).toUpperCase() + op.slice(1)}</p>)}
            </div>
            {opt === 'shows' && <Shows navigate={navigate} shows={shows} dispatch={dispatch} />}
            {opt === 'feed' && <Feed dispatch={dispatch} />}
            {opt === 'users' && <Users dispatch={dispatch} creator={creator} />}
        </section >
    }
    catch (err) {
        console.log(err)
        return <Error />
    }
}