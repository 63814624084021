import Axios from 'axios'

const BASE_URL = process.env.NODE_ENV === 'production' ?
    'https://server.pikme.tv/'
    : '//localhost:3029/'

var axios = Axios.create({
    withCredentials: true
})

export const httpService = {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data)
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data)
    },
    uploadImage,
}

async function uploadImage(file) {
    const formData = new FormData()
    formData.append('image', file)
    const res = await axios({
        url: `${BASE_URL}handle-creator/upload-image`,
        method: 'POST',
        data: formData
    })
    return res.data
}

async function ajax(endpoint, method = 'GET', data = null) {
    try {
        const res = await axios({
            url: `${BASE_URL}${endpoint}`,
            method,
            data,
            params: (method === 'GET') ? data : null,
        })
        return res.data
    } catch (err) {
        throw err
    }
}