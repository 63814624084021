import { useState, useRef } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { io } from "socket.io-client"
import { setUpperPopup } from '../store/actions/general.actions'
import { make2digits } from '../services/utils'
import { httpService } from "../services/http.service"
const roomId = process.env.NODE_ENV === 'production' ? 'main' : 'main-test'
let socket = null

export function Chat() {
    const dispatch = useDispatch()
    const creator = useSelector((state) => state.creator)
    const [messages, setMessages] = useState([])
    const [menu, setMenu] = useState('')
    const [image, setImage] = useState('')
    const [pinned, setPinned] = useState(false)
    const [isConnectionActive, setIsConnectionActive] = useState(true)
    const timeRef = useRef()
    const scrollRef = useRef()
    const messagesBuffer = useRef([])

    let nickName = (creator && creator.nickName) ? creator.nickName : "Guest" + Math.floor(Math.random() * 10000)

    useEffect(() => {
        connectSocket()
        const interval = setInterval(() => {
            if (!messagesBuffer.current.length) return
            setMessages((prevMessages) => {
                const updated = [...prevMessages, ...messagesBuffer.current]
                messagesBuffer.current = []
                if (updated.length > 150) return updated.slice(-50)
                return updated
            })
            if (scrollRef.current) {
                const scrollHeight = scrollRef.current.scrollHeight
                const scrollTop = scrollRef.current.scrollTop
                const clientHeight = scrollRef.current.clientHeight
                if (scrollHeight - scrollTop - clientHeight < 170) setTimeout(() => scrollRef.current.scrollTop = scrollHeight, 100)
            }
        }, 1500)
        return () => {
            clearInterval(interval)
            if (!socket) return
            socket.off("message")
            socket.off("message-bulk")
            socket.off("viewers-update")
            socket.off("update-pinned")
            socket.off("disconnect")
            socket.off("connect_error")
            socket.emit('leaveRoom', roomId)
            socket.close()
            socket = null
        }
    }, [])

    const connectSocket = () => {
        if (socket) {
            socket.close()
            socket = null
        }
        socket = process.env.NODE_ENV === 'production' ? io('https://chat-main.pikme.tv', { transports: ['websocket'] }) : io('http://localhost:3032', { transports: ['websocket'] })

        socket.emit('joinRoom', { username: creator.nickName, roomId: roomId, userId: creator._id })

        socket.on('message-bulk', (m) => {
            setMessages(m)
            setTimeout(() => {
                if (scrollRef.current) scrollRef.current.scrollTop = scrollRef.current.scrollHeight + 400
            }, 100)
        })

        socket.on('message', (message) => messagesBuffer.current.push(message))
        socket.on('disconnect', () => setIsConnectionActive(false))
        socket.on('connect_error', () => setIsConnectionActive(false))
        socket.on('update-pinned', (p) => setPinned(p))
        setIsConnectionActive(true)
    }

    const sendMessage = (ev) => {
        if (ev) ev.preventDefault()
        const text = document.getElementById('input').value
        if (text === "" && !image) return
        const date = new Date()
        const formatted = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${make2digits(date.getMinutes())}`
        let m = { nickName, text, color: '#487CFC', roomId: roomId, image: creator.image, userId: creator._id, date: formatted }
        if (image) m.attachedImage = image
        if (socket) socket.emit('chat', m)
        setImage('')
        document.getElementById('input').value = ''
        if (scrollRef.current) setTimeout(() => scrollRef.current.scrollTop = scrollRef.current.scrollHeight + 400, 1500)
    }

    const muteUser = () => {
        socket.emit('mute', { userId: menu.userId, roomId, time: timeRef.current.value, phone: creator.phone })
        dispatch(setUpperPopup('muted'))
        setMenu('')
    }

    const deleteMessage = () => {
        socket.emit('delete', { msg: menu, phone: creator.phone })
        const m = messages.filter(m => m.id !== menu.id)
        setMessages(m)
        dispatch(setUpperPopup('deleted-message'))
        setMenu('')
    }

    const pinMessage = (close = false) => {
        socket.emit('pin', { msg: close ? null : menu, phone: creator.phone })
        setMenu('')
    }

    const openMenu = (menu, el, type) => {
        menu.type = type
        setMenu(menu)
    }

    const onImageChange = async (ev) => {
        try {
            if (!ev.target.files[0]) return setImage('')
            if (!ev.target.files[0].type.startsWith('image/')) return dispatch(setUpperPopup('image-only'))
            const uploaded = await httpService.uploadImage(ev.target.files[0])
            setImage(uploaded)
        }
        catch (err) {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    return <div className="chat-box chat-page" style={{ width: '100%', overflowY: 'hidden', padding: '0 10px' }} >
        {pinned && <div className="pinned">
            <span onClick={() => pinMessage(true)} className="material-symbols-outlined remove">close</span>
            <p className="message-text" >
                <span style={{ color: pinned.color }}>{pinned.nickName} : </span>
                <span style={{ marginLeft: "5px", fontWeight: "lighter", wordBreak: 'break-all' }} >{pinned.text}</span>
            </p>
            <img src={pinned.image} />
        </div>}
        <div ref={scrollRef} className="messages-container" >
            {messages.map((message) => <div style={{ wordBreak: 'break-word',width:'100%' }} key={message.id}>
                <p className="message-text" >
                    {message.verified && <span className="material-symbols-outlined verified">verified</span>}
                    <span onClick={(ev) => openMenu(message, ev.target, 'mute')} style={{ color: message.color }}>{'‏' + message.nickName} : </span>
                    <span onClick={(ev) => openMenu(message, ev.target, 'pin')} style={{ marginLeft: "5px", fontWeight: "lighter" }} >{'‏' + message.text}</span>
                </p>
                {message.attachedImage && <div className="image-wrapper">
                    <img src={message.attachedImage} className="attached-image" />
                </div>}
            </div>)}
        </div>

        {menu && <div className="ban-menu" style={{ top: 50, maxWidth: 300, left: 170 }}>
            {menu.type === 'mute' ? <><div className="name">{menu.nickName}</div>
                <div className="selects">
                    <p>Mute : </p>
                    <select ref={timeRef}>
                        <option value="60">1 minute</option>
                        <option value="600">10 minutes</option>
                        <option value="1800">30 minutes</option>
                        <option value="3600">1 hour</option>
                        <option value="10800">3 hours</option>
                        <option value="86400">1 day</option>
                        <option value="604800">1 week</option>
                    </select>
                </div>
            </> :
                <div style={{display:'flex',width:'100%'}}>
                    <p>{menu.text}</p>
                </div>}
            <div className="buttons">
                <div onClick={() => { menu.type === 'mute' ? muteUser() : pinMessage() }}>{menu.type === 'mute' ? 'suspend user' : 'pin'}</div>
                {menu.type === 'pin' && <div onClick={deleteMessage}>Delete message</div>}
                <div onClick={() => setMenu(false)}>Cancel</div>
            </div>
        </div>}

        {isConnectionActive ? <>
            {image && <div className="image-wrapper">
                <img src={image} className="attached-image" style={{ marginBottom: '10px' }} />
            </div>}
            <form className="chat-box-input" onSubmit={sendMessage} >
                <input autoComplete="false" type="file" className="non-appear" accept="image/*" id='image-field' onChange={onImageChange} />
                <button className="button"><span style={{ transform: 'scale(-1)' }} className="material-symbols-outlined send">send</span></button>
                <label className="button" htmlFor='image-field'>{image ? <span className="material-symbols-outlined send">check</span> : <span className="material-symbols-outlined send">add</span>}</label>
                <input id="input" type="text" placeholder="הקלד הודעה..." />
            </form>
        </>
            : <p onClick={connectSocket} className="reconnect-chat">Connection error, click to reconnect.</p>}
    </div>
}